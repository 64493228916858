<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                    <b-row>
                      <b-col lg="11" sm="12">
                        <ValidationProvider name="Region ID" vid="region_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="3"
                              label-for="region_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('bsri_config.region_name') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="rwmMaping.region_id"
                              :options="rOfficeList"
                              id="region_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Created Date" vid="created_date" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="created_date"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                                {{$t('bsri_config.created_date')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input class="form-control"
                                    v-model="rwmMaping.created_date"
                                    placeholder="Select Date"
                                    id="datepicker"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                          </b-form-group>
                        </ValidationProvider>
                        <b-row v-for="(wordn,index) in rwmMaping.details" :key="index">
                          <b-col xl="5" lg="5" sm="8">
                              <ValidationProvider name="Mill Type Id" vid="mill_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="mill_type_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{ $t('bsri_config.mill_type') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="wordn.mill_type_id"
                                    :options="millTypeList"
                                    id="index"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="5" lg="5" sm="8">
                              <ValidationProvider name="Mill Id" vid="mill_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-for="mill_id"
                                    label-cols-sm="4"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{ $t('bsri_config.mill_name') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="wordn.mill_id"
                                    :options="millInfoList"
                                    id="index"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col xl="1" lg="1" sm="4">
                              <b-button v-show="index == rwmMaping.details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                              <b-button v-show="index || ( !index && rwmMaping.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { rwmMapingStore, rwmMapingUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getRwmMapingData()
      this.rwmMaping = tmp
      const detailsInfo = tmp.details.map(item => {
        return { mill_type_id: item.mill_type_id, mill_id: item.mill_id }
      })
      this.rwmMaping.details = detailsInfo
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      rwmMaping: {
        region_id: '0',
        created_date: '',
        details: [
          {
            mill_type_id: '0',
            mill_id: '0'
          }
        ]
      },
      classList: [],
      millList: []
    }
  },
  computed: {
    rOfficeList: function () {
      const fiscalyearData = this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 85)
      return fiscalyearData.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    millTypeList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.millTypeList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    millInfoList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    }
  },
  watch: {
    // 'rwmMaping.details.mill_type_id': function (newVal, oldVal) {
    //   this.millList = this.getMillInfolist(newVal)
    // }
  },
  methods: {
    getRwmMapingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${rwmMapingUpdate}/${this.id}`, this.rwmMaping)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, rwmMapingStore, this.rwmMaping)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    getMillInfolist (milltypeId) {
      const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.id === milltypeId)
      this.millList = objectData.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    add () {
      const tampbpSetup = {
        mill_type_id: '0',
        mill_id: '0'
      }
      const key1 = parseInt(this.rwmMaping.details.length - 1)
      const item = this.rwmMaping.details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
          this.rwmMaping.details.push(tampbpSetup)
      }
    },
    remove (key) {
        this.rwmMaping.details.splice(key, 1)
    }
  }
}
</script>
