
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_config.region_wise_mill_mapping') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('bsri_config.region_name')"
                            label-for="region_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.region_id"
                                :options="rOfficeList"
                                id="region_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('bsri_config.mill_type')"
                            label-for="mill_type_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.mill_type_id"
                                :options="millTypeList"
                                id="mill_type_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col> -->
                </b-row>
                <b-row>
                  <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('bsri_config.mill_name')"
                            label-for="mill_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.mill_id"
                                :options="millInfoList"
                                id="mill_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col> -->
                </b-row>
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.region_wise_mill_mapping_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                          <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                          <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                              <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                          </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('bsri_config.region_wise_mill_mapping_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { rwmMapingList, rwmMapingStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        region_id: '0'
        // mill_type_id: '0',
        // mill_id: '0'
      },
      region_wize_mill_mapping: {
        details: [
          {
            mill_type_id: '0',
            mill_id: '0'
          }
        ]
      },
      rows: [],
      gradeList: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    rOfficeList: function () {
      const fiscalyearData = this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 85)
      return fiscalyearData.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text_en }
          }
      })
    },
    millTypeList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.millTypeList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    millInfoList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.millInfoList
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_config.region_wise_mill_mapping') + ' ' + this.$t('globalTrans.entry') : this.$t('bsri_config.region_wise_mill_mapping') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_config.region_name'), class: 'text-left' },
          { label: this.$t('bsri_config.mill_name'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'region_name_bn' },
          { key: 'mill_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'region_name' },
          { key: 'mill_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, rwmMapingStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, rwmMapingList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const fiscalYearObject = this.$store.state.commonObj.officeList.find(olist => olist.value === item.region_id)

        const fiscalData = { region_name: fiscalYearObject.text_en, region_name_bn: fiscalYearObject.text_bn }
        this.region_wize_mill_mapping.details = item.details.map(item2 => {
          const millTypeList = this.$store.state.incentiveGrant.commonObj.millTypeList
          const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
          const millTypeObject = millTypeList.find(data => data.value === item2.mill_type_id)
          const millInfoObject = millInfoList.find(data => data.value === item2.mill_id)
          const millTypeData = { millType_name: millTypeObject.text_en, millType_name_bn: millTypeObject.text_bn }
          const millInfoData = { mill_name: millInfoObject.text_en, mill_name_bn: millInfoObject.text_bn }
          return Object.assign({}, item2, millTypeData, millInfoData)
        })
        const millInfoArray = item.details
        const millInfoList = this.$store.state.incentiveGrant.commonObj.millInfoList
          let millInfoData = millInfoArray.map(millInfoItem => {
              const millObject = millInfoList.find(millInfo => millInfo.value === millInfoItem.mill_id)
              return this.$i18n.locale === 'en' ? millObject.text_en : millObject.text_bn
          })
          millInfoData = millInfoData.join(', ')
        return Object.assign({}, item, this.region_wize_mill_mapping, fiscalData, { mill_name: millInfoData })
      })
      return listData
    }
  }
}
</script>
